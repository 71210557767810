import React from 'react'
import { graphql } from 'gatsby'

import Markdown from 'react-markdown'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import ArticleRow from '../components/ArticlesRow'

const IndexPage = ({ data }) => {

  return (
    <Layout pageData={data.strapiHomepage}>
      <Seo title={data.strapiHomepage.title} />
      <div className="container content">
        <Markdown>
          { data.strapiHomepage.content }
        </Markdown>
        <ArticleRow />
      </div>
    </Layout>
  )
}
export default IndexPage

export const pageQuery = graphql`
  query {
    strapiHomepage {
      title
      subtitle
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData(
              transformOptions: {grayscale: true}
            )
          }
        }
      }
    }
  }
`
