import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ArticleLink from './ArticleLink'

const ArticlesRow = () => {

  const data = useStaticQuery (
    graphql`
      query {
        allStrapiArticle(
          sort: {fields: created_at, order: DESC}
        )
        {
          edges {
            node {
              slug
              title
              published_at(formatString: "dddd, D MMMM YYYY")
              updated_at(formatString: "dddd, D MMMM YYYY")
              sticky
              tags {
                name
              }
              content
              cover {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 600, height: 400,
                      transformOptions: {grayscale: true}
                    )
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  const articles = data.allStrapiArticle.edges

  return(
    <div className="columns is-multiline">
      {articles.map((article, i) =>
        <div className="column is-4" key={i}>
          <ArticleLink article={article}  />
        </div>
      )}
    </div>
  )

}

export default ArticlesRow
